import React, { lazy } from 'react';
import { RouteDefinition } from '../models/route-definition';

const Dashboard = lazy(() => import('../Component/Pages/dashboard/dashboard').then(({ Dashboard }) => ({ default: Dashboard })),);
const UserSettings = lazy(() => import('../Component/Pages/Setting/UserSettings').then(({ UserSettings }) => ({ default: UserSettings })),);
const WhatToAsk = lazy(() => import('../Component/Pages/Wta/WhatToAsk').then(({ WhatToAsk }) => ({ default: WhatToAsk })),);
const Unauthorized = lazy(() => import('../Component/Pages/Unauthorized/Unauthorized').then(({ Unauthorized }) => ({ default: Unauthorized })),);
const Login = lazy(() => import('../Component/auth/login').then(({ Login }) => ({ default: Login })),);
const SignInStart = lazy(() => import('../Component/auth/login').then(({ SignInStart }) => ({ default: SignInStart })),);
const SignInEnd = lazy(() => import('../Component/auth/login').then(({ SignInEnd }) => ({ default: SignInEnd })),);
const DULead = lazy(() => import('../Component/Pages/DULead/DULead').then(({ DULead }) => ({ default: DULead })),);
const ERConsole = lazy(() => import('../Component/Pages/ERConsole/ERConsole').then(({ ERConsole }) => ({ default: ERConsole })),);
const Report = lazy(() => import('../Component/Pages/Report/Report').then(({ Report }) => ({ default: Report })),);

const routes: RouteDefinition[] = [
    {
        route: '/auth/login',
        component: <Login />,
        authenticated: false,
        exact: true
    },
    {
        route: '/auth/signinstart',
        component: <SignInStart />,
        authenticated: false,
        exact: true
    },
    {
        route: '/auth/signinend',
        component: <SignInEnd />,
        authenticated: false,
        exact: true
    },
    {
        route: '/',
        component: <Dashboard />,
        authenticated: true,
        exact: true
    },
    {
        route: '/dashboard',
        component: <Dashboard />,
        authenticated: true,
        exact: true
    },
    {
        route: '/settings',
        component: <UserSettings />,
        authenticated: true,
        exact: true
    },
    {
        route: '/wta',
        component: <WhatToAsk />,
        authenticated: true,
        exact: true
    },
    {
        route: '/tab',
        component: <WhatToAsk />,
        authenticated: true,
        exact: true
    },
    {
        route: '/dulead',
        component: <DULead />,
        authenticated: true,
        exact: true
    },
    {
        route: '/erconsole',
        component: <ERConsole />,
        authenticated: true,
        exact: true
    },
    {
        route: '/unauthorized',
        component: <Unauthorized />,
        authenticated: true,
        exact: true
    },
    {
        route: '/report',
        component: <Report />,
        authenticated: true,
        exact: true
    }
];

export default routes;