//Employee feedback -> On submission records get updated in DiPA database
export class InputDetail {
    InputDetail !: any;
}
export class EmployeeFeedbackModel {
    EnterpriseId !: string;
    ScenarioId!: string;
    Comment!: string;
    CareerLevel!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous!: boolean;
}

export class FeedbackPromptResponseDetails {
    QuestionId !: string;
    OptionId !: string;
}