import { FeedbackPromptResponseDetails } from "./EmployeeFeedbackModel";
//EMNJDOJA28Model  Prompt -> On submission records get pushed to Strand.
export class EMNJDOJA28DModel {
    EnterpriseId!: string;
    CardUniqueId!: string;
    FeedbackPromptComments!:string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous !: boolean;
}