import { createSlice } from "@reduxjs/toolkit";

const initState: CounterState = {
    counter: { count: 0 }
};

// Slice
const counterReducer = createSlice({
	name: "COUNTER",
	initialState: initState,
	reducers: {
		setCounter: (state, { payload }) => {
			return { ...state, counter: payload };
		}
	}
});

export const { setCounter } = counterReducer.actions;

export default counterReducer.reducer;
