import { FeedbackPromptResponseDetails } from "./EmployeeFeedbackModel";
import { FeedbackPromptComments } from "./connectPromptModel";
//EMNJReminder Prompt -> New Joiner should be able to receive a reminder nudge on DOJ+15 feedback nudge if he did not submit the feedback in first time -> On submission records get pushed to Strand.
export class EMNJReminderModel {
    EnterpriseId !: string;
    CardUniqueId!: string;
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptComments!: FeedbackPromptComments[];
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous !: boolean;
    IsCPQFeedbackPrompt !: boolean;
}