import { PublicClientApplication } from '@azure/msal-browser';
import { Auth } from '../helper/constants';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID!,//'deeb2831-664c-475b-9c8f-2eeebda80a8f',
        redirectUri: process.env.REACT_APP_REDIRECT_URI!,//`${window.location.origin}/${Auth.signInEndPage}`,
        navigateToLoginRequestUrl: false,
        authority: `${process.env.REACT_APP_AUTHORITY! + process.env.REACT_APP_TENANT_ID!}`,
        tenant: process.env.REACT_APP_TENANT_ID!,
        extraQueryParameter: process.env.REACT_APP_SCOPE, // 'scope=openid+profile',
        endpoints: process.env.REACT_APP_ENV === "dev" ? Auth.devAuthenticatedDomains : (process.env.REACT_APP_ENV === "staging" ? Auth.stageAuthenticatedDomains : (process.env.REACT_APP_ENV === "prod" ? Auth.prodAuthenticatedDomains : Auth.devAuthenticatedDomains))
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};
export const msalInstance = new PublicClientApplication(msalConfig);


export const loginRequest = {
    scopes: [process.env.REACT_APP_CLIENT_ID! + '/User.Read'],
    human_api_scope: [process.env.REACT_APP_HUMAN_API_SCOPE + '/50874.Api.ProfilePic']
};
