import { TeamsThemes } from "../../helper/constants";
import { ApplicationStore } from "../../types/AppState.type";
import { createSlice } from "@reduxjs/toolkit";


const initState: ApplicationStore.AppThemeState = {
    themeName: TeamsThemes.default
};

// Slice
const themeReducer = createSlice({
	name: "THEME",
	initialState: initState,
	reducers: {
		setTheme: (state, { payload }) => {
			return { ...state, themeName: payload };
		}
	}
});

export const { setTheme } = themeReducer.actions;

export default themeReducer.reducer;
