import { useDispatch, useSelector } from 'react-redux';
import IUser from '../../models/user';
import { ApplicationStore } from '../../types/AppState.type';
import { setUserAuthincated } from "../reducers/userSlice";



export const useUserState = (): [ApplicationStore.UserState, (user: IUser) => void] => {
    const dispatch = useDispatch();
    
    return [useSelector((state: ApplicationStore.CombineState) => state.UserState),
    (user: IUser) => { dispatch(setUserAuthincated(user)) }];
}
