import { createSlice } from "@reduxjs/toolkit";
import { ApplicationStore } from "../../types/AppState.type";

const initState: ApplicationStore.AppLoadingState = {
    isLoading: false,
    loaderMessage: 'Loading...'
};

// Slice
const loaderReducer = createSlice({
	name: "LOADER",
	initialState: initState,
	reducers: {
		setLoader: (state, action) => {
			const { loaderState } = action.payload;
			return { ...state, isLoading: loaderState?.isLoading, loaderMessage: loaderState?.loaderMessage};
		}
	}
});

export const { setLoader } = loaderReducer.actions;

export default loaderReducer.reducer;
