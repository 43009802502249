import { FeedbackPromptResponseDetails } from "./EmployeeFeedbackModel";

//ExperienceManagerFeedBack Prompt -> On submission records get updated in Dipa database.
export class EMNJACKA6AND12D1Model {
    EnterpriseId!: string;
    CardUniqueId!: string;
    FeedbackPromptComments!:string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous !: boolean;
}