import React, { Suspense } from 'react';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import { routes as routeDefinitions } from '../config';
import { Login } from '../Component/auth/login';
import { isNotIframe } from '../helper/Utility'
import { SkeletonLoader } from '../Component/common/loader-northstar';

interface IAppRoutesProps {
  isLoggedIn: boolean
}

export const AppRoutes: React.FC<IAppRoutesProps> = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return (
      <Suspense fallback={<SkeletonLoader rows={5} />}>
        <Switch>
          {routeDefinitions.map((route) => (
            <Route key={route.route} path={route.route} exact={route.exact}>
              {route.component}
            </Route>
          ))}
        </Switch>
      </Suspense>
    );
  } else {
    if (isNotIframe()) {
      let copyOfRoutes = routeDefinitions;
      for (let route of copyOfRoutes) {
        if (route.authenticated) {
          route.component = <Login />
        }
      }
      return (
        <Suspense fallback={<SkeletonLoader rows={5} />}>
          <Switch>
            {routeDefinitions.map((route) => (
              <Route key={route.route} path={route.route} exact={route.exact}>
                {route.component}
              </Route>
            ))}
          </Switch>
        </Suspense>
      );
    } else {
      return <Login />
    }
  }
}