import React from "react";
import ReactDOM from "react-dom";
import { TeamsApp } from "./TeamsApp";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./helper/AuthContext";
import { InteractionType } from "@azure/msal-browser";

ReactDOM.render(
  <React.StrictMode>
    {process.env.REACT_APP_ENV === "staging" ||
    process.env.REACT_APP_ENV === "prod" ? (
      <TeamsApp />
    ) : (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <TeamsApp />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
