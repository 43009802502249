import React from 'react'
import { Box, Flex, Loader, Skeleton } from '@fluentui/react-northstar'
import { Provider as ThemeProvider } from '@fluentui/react-northstar';

interface ITeamsLoaderProps {
    label?: string
}

export const TeamsLoader: React.FC<ITeamsLoaderProps> = ({ label }) => {
    return <ThemeProvider.Consumer
        render={theme => {
            return <Loader size="medium"
                // style={{ 'backgroundColor': theme.siteVariables.bodyBackground }}
                className="box" label={label} />
        }}
    />
}

export const BoxLoader: React.FC<ITeamsLoaderProps> = ({ label }) => {
    //...theme.siteVariables.colorScheme.brand
    return <ThemeProvider.Consumer
        render={theme => {
            return <Box style={{
                'width': '100%',
                'padding': '20px',
                'justifyContent': 'center',
                'display': 'flex',
                'backgroundColor': theme.siteVariables.colorScheme.default.background4
            }}>
                <Loader label={label}></Loader>
            </Box >
        }}
    />
}

export const SkeletonLoader: React.FC<{ rows: number }> = ({ rows }) => {
    //...theme.siteVariables.colorScheme.brand
    return <ThemeProvider.Consumer
        render={theme => {
            return <Box style={{
                'width': '100%',
                'padding': '20px',
                'justifyContent': 'center',
                'display': 'flex',
                // 'backgroundColor': theme.siteVariables.colorScheme.default.background1
            }}>

                <Skeleton animation="pulse">
                    <Flex column gap="gap.small">
                        <Flex gap="gap.small">
                            <Skeleton.Shape round width="50px" height="50px" />
                            <Flex gap="gap.small" column>
                                <Skeleton.Line width="200px" />
                                <Skeleton.Line width="150px" />
                            </Flex>
                        </Flex>
                        {Array.from(Array(rows).keys()).map((item, index) => (
                            <Skeleton.Line key={index} width={Math.floor(Math.random() * (100 - 50) + 50) + '%'} />
                        ))}
                        <Flex gap="gap.small">
                            <Skeleton.Button />
                            <Skeleton.Button />
                        </Flex>
                    </Flex>
                </Skeleton>
            </Box >
        }}
    />
}