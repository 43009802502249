import { IDemoUser } from "../../types/DemoUserState.type";
import { ApplicationStore } from "../../types/AppState.type";
import { AnyAction, ThunkDispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";

const initState: ApplicationStore.DemoUserState = {
    users: [],
    isLoading: false
};
export const getUsersData = createAsyncThunk(
	"users", 
	async () => {
		return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IDemoUser[]> => {
			dispatch(setLoading(true));
			return await axios.get('https://jsonplaceholder.typicode.com/users')
				.then((res: AxiosResponse<IDemoUser[]>) => {
					dispatch(setUsers(res.data));
					setTimeout(() => {
						dispatch(setLoading(false));
					}, 2000);
					return res.data
				})
				.catch((err: AxiosError<any>) => {
					return [];
				});
		}
  });

// Slice
const userDemoReducer = createSlice({
	name: "USER_DEMO",
	initialState: initState,
	reducers: {
		setUsers: (state, action) => {
			const { users } = action.payload;
			return { ...state, users: users };
		},
        addUser: (state, action) => {
			const { user } = action.payload;
			return { ...state, users: state.users.concat(user) };
		},
        setLoading: (state, action) => {
			const { flag } = action.payload;
			return { ...state, isLoading: flag };
		},
	},
});

export const { setUsers, addUser, setLoading } = userDemoReducer.actions;

export default userDemoReducer.reducer;
