//New-Joiner feedback -> On submission records get inserted for different weeks and updated for same week in DiPA and Strand databases.
export class NJQEmployeeFeedback {
    Comment !: string;
    EnterpriseId !: string;
    CareerLevel !: string | undefined;
    ScenarioId !: string;
    NewJoinerFeedbackDetails !: NewJoinerFeedbacks[];
    NewJoinerWeekPrompt !: string;
    IsPostAnonymous!: boolean;
}

export class NewJoinerFeedbacks {
    QuestionId!: string;
    OptionId!: string;
}