
export class InputDetail {
    InputDetail !: any;
}
export class NJEmployeeFeedbackModel
// NJ Questionnaire -> On submission records get pushed to Strand.
{
    CategoryType !: string;
    EmployeeFeedback !: NJEmployeeFeedback;
}


export class NJEmployeeFeedback
// NJ Questionnaire -> On submission records get pushed to Strand.
{
    EnterpriseId !: string;
    QuestionIds !: FeedbackQuestionId[];
    CardUniqueId !: string;
    CommentTextFirst !: string;
    CommentTextSecond !: string;
    ChannelType !: string;
    FeedbackDate !: string;
    IsPostAnonymous !: boolean;
}

export class FeedbackQuestionId {
    QuestionId !: string;
    FeedbackValue !: string;
}

//Mental wellness survey -> On submission records get updated in DiPA database
export class MentalWellnessFeedback {
    EnterpriseId !: string;
    ScenarioId !: string;
    Comment !: string;
    CareerLevel !: string;
    IsPostAnonymous !: boolean;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[]
}

export class FeedbackPromptResponse {
    QuestionId !: string;
    OptionId !: string;
}
export class TechversantFeedback {
    //Techversant journey survey -> On submission records get updated in DiPA database
    EnterpriseId !: string;
    ScenarioId !: string;
    CareerLevel !: string;
    IsPostAnonymous !: boolean;
    FeedbackPromptComments !: FeedbackPromptComments[]
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[]
}
export class FeedbackPromptComments {
    QuestionId !: string;
    Comment !: string;
}