import { Constants } from '../helper/constants';
import jwt from "jwt-decode";
import IUser from '../models/user';
import TestIdMapper from './TestIdMapping';
import { Utilities } from './Utility';

export default class TokenHelper {

    /**
     * 
     */
    public static IsUserLoggedIn(): boolean {
        const token = TokenHelper.GetToken()!;
        return token !== undefined && token !== null && token.length > 0
    }

    public static GetToken(): string {
        let token = localStorage.getItem(Constants.AccessTokenKey)
        if (token && token != null) {
            return token;
        }
        return '';
    }

    public static DecodeToken(): IUser {
        const token = TokenHelper.GetToken();
        const decodedValue = jwt<any>(token!);
        var userEmail: string = decodedValue.unique_name;
        var firstName: string = decodedValue.given_name;
        var companyCode: string = decodedValue.companyCode;
        var correlationId = Math.round(Date.now() / 1000);
        var careerLevel: string = decodedValue.jobFamilyDescription;
        var jobTitle: string = decodedValue.jobTitle;
        var personnelNumber: string = decodedValue.personnelNumber;
        var location: string = decodedValue.location;
        if (userEmail && userEmail.length > 0) {
            userEmail = userEmail.split('@')[0];
        }

        if (userEmail.toLowerCase().startsWith('t10029dipa')) {
            firstName = decodedValue.name;
            var testMappingList = TestIdMapper.getTestIdMapping(userEmail);
            userEmail = testMappingList?.communicationEmail?.split('@')[0];
            companyCode = testMappingList?.companyCode;
            careerLevel = testMappingList?.careerLevel;
            location = testMappingList?.location;
            personnelNumber = testMappingList?.personnelNumber;
        }
        return {
            candidateEnterpriseId: userEmail,
            accessToken: token!,
            enterpriseId: userEmail,
            isAuthenticated: true,
            firstName: firstName,
            CorrelationId: correlationId,
            careerLevel: careerLevel,
            companyCode: companyCode,
            IsAIGUser: Utilities.IsAIGUser(companyCode),
            jobTitle: jobTitle,
            personnelNumber: personnelNumber,
            location: location
        }
    }
}