import { ApplicationInsights, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import TokenHelper from '../helper/tokenHelper';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTAL_KEY,
        enableCorsCorrelation: true,
        enableAjaxErrorStatusText: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    const objUser = TokenHelper.DecodeToken();
    if (objUser !== undefined && objUser !== null) {
        var telemetryItem = envelope.baseData;
        telemetryItem!.properties['UserName'] = objUser.enterpriseId;
        telemetryItem!.properties['CorrelationId'] = objUser.CorrelationId;
    } else {
        telemetryItem!.properties['ErrorMessage'] = 'No token found for user';
    }
});

/**

 * Log Custom Error in Application Insight
 * @param error
 * @param errorMessage
 * @param params
 */
export const logCustomError = (error: any, errorMessage?: string, params?: any): void => {
    appInsights.trackException({
        exception: error,
        properties: {
            'Error': error,
            'ErrorMessage': errorMessage,
            'Params': params
        }
    });
}

/**
 * Log Custom Information in Application Insight
 * @param infoMessage 
 * @param params 
 */
export const logCustomInfo = (infoMessage: string, params?: any): void => {
    appInsights.trackTrace({
        message: infoMessage,
        severityLevel: SeverityLevel.Information
    }, {
        'Params': params
    });
}

export { reactPlugin, appInsights };
