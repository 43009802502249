import { Button, Box, Text } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import AuthHelper from '../../helper/auth-helper';
import { msalInstance } from '../../helper/AuthContext';
import { Utilities } from '../../helper/Utility';
import { useUserState } from '../../redux/hooks/authenticationHooks';
import { Constants } from '../../helper/constants';
import TokenHelper from '../../helper/tokenHelper';
import { app } from '@microsoft/teams-js';
import { useHistory } from 'react-router-dom';

export const Login: React.FC<{}> = () => {
    localStorage.setItem("origin", window.location.href);
    var componet = localStorage.getItem("origin")!;
    var isForceLogin: boolean = componet.indexOf('dulead') > -1;
    if (((window === window.parent) && (window === window.top)) || isForceLogin) {
        Utilities.LogInfo("In browser specific code", "Login.tsx -> Login");
        localStorage.setItem("LoginType", "Force");
        localStorage.setItem("Channel", "web");
        if (AuthHelper.IsUserLoggedIn()) {
            AuthHelper.AcquireTokenForResource();
        }
        else {
            msalInstance["browserStorage"].clear();
            msalInstance.loginRedirect();
        }
        return <Text content="Authenticating..." size="large" />
    }
    else {
        localStorage.setItem("Channel", "IFrame");
        Utilities.LogInfo("In IFrame function", "Login.tsx -> Login");
        return <SSOSignIn />
    }
};

export const LoginButton: React.FC<{}> = () => {
    return <Box style={{ 'padding': '8px' }}>
        <Box>
            <Text content="Please Sign In"></Text>
        </Box>
        <Box style={{ 'paddingTop': '8px' }}>
            <Button primary onClick={AuthHelper.Login} >Sign In</Button>
        </Box>
    </Box>
}

export const SignInStart: React.FC<{}> = () => {
    AuthHelper.StartSignIn();
    return <Text content="SignIn Start...." size="large" />
}

export const SignInEnd: React.FC<{}> = () => {
    const localstr = localStorage.getItem("origin")!;
    if (localstr?.indexOf("dulead") > -1) {
        AuthHelper.EndSignIn(false, true, localstr);
    } else {
        AuthHelper.EndSignIn();
    }
    return <Text content="Sign-In End" size="large" />
}

/**
 * SSO Sign in for TeamsTab
 * @returns
 */
export const SSOSignIn: React.FC<{}> = () => {

    const [isLoginSuccess, setIsLoginSuccess] = useState<boolean>(true);
    const [user, setUser] = useUserState(); //eslint-disable-line @typescript-eslint/no-unused-vars

    Utilities.LogInfo("In SSOSignIn function", "Login.tsx -> SSOSignIn");
    const history = useHistory ();
    useEffect(() => {
        AuthHelper.GetTeamsContext().then((user) => {
            msalInstance.ssoSilent(user!).then(async res => {
                let token = await AuthHelper.getRedirectToken(); //gets token for api
                localStorage.setItem(Constants.AccessTokenKey, token!);
                let HumanApitoken = await AuthHelper.getHumanApiToken(); //gets token for humanapi
                localStorage.setItem(Constants.HumanApiTokenKey, HumanApitoken!);
                app.notifySuccess();
                localStorage.setItem("LoginType", "SSO");
                const objUser = TokenHelper.DecodeToken();
                setUser({
                    accessToken: token!,
                    // enterpriseId: objUser?.enterpriseId!,
                    enterpriseId: objUser?.enterpriseId!,
                    candidateEnterpriseId: objUser?.enterpriseId!,
                    firstName: objUser?.firstName!,
                    isAuthenticated: true,
                    CorrelationId: objUser?.CorrelationId,
                    careerLevel: objUser?.careerLevel,
                    companyCode: objUser?.companyCode,
                    IsAIGUser: Utilities.IsAIGUser(objUser?.companyCode),
                    jobTitle: objUser?.jobTitle,
                    personnelNumber: objUser?.personnelNumber,
                    location: objUser?.location
                })
                setIsLoginSuccess(true);
                Utilities.LogInfo("In SSOSignIn successCallback", "Login.tsx -> SSOSignIn");
                history?.push(window.location.href);
            }).catch(error => {
                app.notifyFailure(
                    {
                        reason: app.FailedReason.AuthFailed,
                        message: error
                    }
                );
                Utilities.LogError(error, "Login.tsx -> SSOSignIn");
                setIsLoginSuccess(false);
            });
        }).catch(error => {
            Utilities.LogError(error, "Login.tsx -> GetTeamsContext SSOSignIn");
            setIsLoginSuccess(false);
        });
    });

    return <>{isLoginSuccess ? <h1>SSO SignIn in progress</h1> :
        <LoginButton />}
    </>
}