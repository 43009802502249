import { ApplicationStore } from "../../types/AppState.type";
// import AuthHelper from "../../helper/auth-helper";
import TokenHelper from "../../helper/tokenHelper";
import { createSlice } from "@reduxjs/toolkit";
// import PilotUser from '../../helper/pilotUsers';

const setInitialStat = (): ApplicationStore.UserState => {

    // /**
    //  * Local Debug only
    //  */
    // return { currentUser: { careerLevel: "12", isAuthenticated: true, enterpriseId: 'pulkit.h.arora', accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCIsImtpZCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCJ9.eyJhdWQiOiJkZWViMjgzMS02NjRjLTQ3NWItOWM4Zi0yZWVlYmRhODBhOGYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNjQ2OTExMTY5LCJuYmYiOjE2NDY5MTExNjksImV4cCI6MTY0NjkxNTA2OSwiYWlvIjoiQVZRQXEvOFRBQUFBMHRRc0ptRVFLTjZTUURuMU5LVGRqOEtZRllDaGsvOXhnYTNrOEdXME1Tcm1yWGpYWjk2Y1RPRUdNckhTVkRkUENwdjFqUWk2cjg5VUtaZWtXZzFhMDJOZ21kUzd4alovKzRSbVZUd0MxbGM9IiwiYW1yIjpbInB3ZCIsIm1mYSJdLCJmYW1pbHlfbmFtZSI6IkFyb3JhIiwiZ2l2ZW5fbmFtZSI6IlB1bGtpdCIsImlwYWRkciI6IjE0NC4zNi43My4zMCIsIm5hbWUiOiJBUk9SQSwgUFVMS0lUIiwibm9uY2UiOiI3NGZkN2ZmZC1iNmZlLTRiZDQtYWU2Ny02Yjg3YmZhNzI4ZTMiLCJvaWQiOiIzYzBjZjllYS1iNGE4LTQ5NzUtOGE1MC04MDMxMWE1MTRmYmYiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtODYxNTY3NTAxLTQxMzAyNzMyMi0xODAxNjc0NTMxLTQ4NTQxMzkiLCJyaCI6IjAuQVNZQURoMGg4MXNTdzBLRzJ6SXJHYVphSWpFbzY5NU1abHRIbkk4dTdyMm9DbzhtQUpvLiIsInJvbGVzIjpbIkRlZmF1bHRfQWNjZXNzIl0sInN1YiI6Imp2VUduaGFJRTkzUFEta3VsaC1fb0RMWVJxMDZkNUkybVo1aE5WSlgyemsiLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1bmlxdWVfbmFtZSI6InB1bGtpdC5oLmFyb3JhQGRzLmRldi5hY2NlbnR1cmUuY29tIiwidXBuIjoicHVsa2l0LmguYXJvcmFAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJ1dGkiOiJ1aDNtdkVMY2lFYThQeVNyYWJKdkFBIiwidmVyIjoiMS4wIn0.adY2A_lL6EELg0y4DAioB1OwWPAeDtWssgrPxfhg58UkycFHZQ5_469D2r5jcZnkaLjyWmmdQwSiIWGR0JoceKqmxEh1TJy-bdmns3QLTZFM9RTesyTchQw1WjQIU8XaZF4tAJZgMA0snjhjTPmg-JdMnt6IPCdSx3DRgGwiQSmCurcB-7xatzpt6k__UDrZKLfs_rePQc8Al7OGA3gwqY1LdSgbyOxkS9u-a7jBm59XDZiC6iiWPJ3H7_vHj3QhhXe1nFBcX5FXWdxfcpcMmVyHbnrR3aUhkYEagz1OO18tF0v9xaIS6UxEyQjho8RJrC5v9Xgqgv7zTrln6BjN3g', firstName: 'Supriya', CorrelationId: Math.round(Date.now() / 1000), IsPilotUser: true, IsAIGUser: false, companyCode: '' } }


    if (TokenHelper.IsUserLoggedIn()) {
        return {
            currentUser: TokenHelper.DecodeToken()!
        };
    } else {
        return {
            currentUser: {
                isAuthenticated: TokenHelper.IsUserLoggedIn(),
                enterpriseId: '',
                candidateEnterpriseId: '',
                accessToken: TokenHelper.GetToken(),
                firstName: '',
                CorrelationId: Math.round(Date.now() / 1000),
                careerLevel: "",
                IsAIGUser: undefined,
                companyCode: '',
                jobTitle: '',
                personnelNumber:'',
                location:''
            }
        }
    }

    //     return {
    //         currentUser: TokenHelper.DecodeToken()!
    //     };
    // } else {
    //     return {
    //         currentUser: {
    //             isAuthenticated: TokenHelper.IsUserLoggedIn(),
    //             enterpriseId: '',
    //             accessToken: TokenHelper.GetToken(),
    //             firstName: '',
    //             CorrelationId: Math.round(Date.now() / 1000)
    //         }
    //     }
    // }
}


// Slice
const userReducer = createSlice({
	name: "USER",
	initialState: setInitialStat(),
	reducers: {
		setUserAuthincated: (state, action) => {
            const userAuthData = action.payload;
			return { ...state, currentUser: userAuthData };
		}
	}
});

export const { setUserAuthincated } = userReducer.actions;

export default userReducer.reducer;
