import { FeedbackPromptResponseDetails } from "./EmployeeFeedbackModel";
import { FeedbackPromptComments } from "./newJoinerFeedbackModel";
//ExperienceManagerFeedBack Prompt -> On submission records get updated in Dipa database.
export class EMFeedBackAPIModel {
    EnterpriseId!: string;
    CardUniqueId!: string;
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    FeedbackPromptComments!: FeedbackPromptComments[];

}