import { useDispatch, useSelector } from 'react-redux';
import { ApplicationStore } from '../../types/AppState.type';
import { setLoader } from "../reducers/loaderSlice";

// export const useLoader = (initialState: ApplicationStore.AppLoadingState | (() => ApplicationStore.AppLoadingState)): [ApplicationStore.AppLoadingState, (isLoading: boolean, loaderMessage?: string) => any] => {

//     const dispatch = useDispatch();

//     return [useSelector((state: ApplicationStore.CombineState) => state.LoadingState),
//     (showLoader: boolean, loadingMessage?: string) => {
//         return dispatch(
//             setLoader({ isLoading: showLoader, loaderMessage: loadingMessage! }))
//     }];
// }

export const useLoader = (): [ApplicationStore.AppLoadingState, (isLoading: boolean, loaderMessage?: string) => any] => {

    const dispatch = useDispatch();

    return [useSelector((state: ApplicationStore.CombineState) => state.LoadingState),
    (showLoader: boolean, loadingMessage?: string) => {
        return dispatch(
            setLoader({ isLoading: showLoader, loaderMessage: loadingMessage! }))
    }];
}

