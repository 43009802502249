//TAWSH prompt -> On submission records get pushed to Strand
export class TAWSHModel {
    EnterpriseId !: string;
    CardUniqueId!: string;
    FeedbackPromptComments !: string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWSPHModel {
    EnterpriseId !: string;
    CardUniqueId!: string;
    FeedbackPromptComments !: string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWPWModel {
    EnterpriseId !: string;
    CardUniqueId!: string;
    FeedbackPromptComments !: string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWFSModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWPTOModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWPIOModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWLHCModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWSWPModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class TAWQCModel {
    EnterpriseId !: string;
    CardUniqueId !: string;
    FeedbackPromptComments !: string[];
    CategoryType !: string;
    ChannelType !: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponse[];
    IsPostAnonymous !: boolean;
}

export class FeedbackPromptResponse {
    QuestionId !: string;
    OptionId  !: string;
}