//Connect prompt -> On submission records get pushed to Strand
export class InputDetail {
    InputDetail !: any;
}
export class ConnectPromptModel {
    EnterpriseId !: string;
    CardUniqueId!: string;
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptComments !: FeedbackPromptComments[];
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous !: boolean;
}

export class FeedbackPromptComments {
    QuestionId !: string;
    Comment  !: string;
}

export class FeedbackPromptResponseDetails {
    QuestionId !: string;
    OptionId  !: string;
}