//CapsuleDUL -> On submission records get pushed to Strand.
export class CapsuleEventFeedbackModel {
    CategoryType !: string;
    EmployeeFeedback !: SupervisorEmployeeFeedback;
}

export class SupervisorEmployeeFeedback {
    EmployeeMood !: string;
    FeedbackText !: string;
    CardUniqueId !: string;
    FeedbackDate !: Date;
    EnterpriseId !: string;
    ChannelType !: string;
    NudgeCategory !: string;
}