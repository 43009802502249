import counterReducer from "./reducers/counterSlice";
import userDemoReducer from "./reducers/userDemoSlice"
import userReducer from "./reducers/userSlice"
import themeReducer from "./reducers/themeSlice"
import loaderReducer from "./reducers/loaderSlice"
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: {
      counterState: counterReducer,
      LoadingState: loaderReducer,
      ThemeState: themeReducer,
      DemoUserState: userDemoReducer,
      UserState: userReducer,
    }
});

export default store;