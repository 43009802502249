import React, { Component } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../Services/TelemetryService";
import { withRouter } from "react-router-dom";

class TelemetryProvider extends Component<{}> {
    render() {
        const { children } = this.props;
        return (
            <>
                {children}
            </>
        );
    }
}

export default withRouter(withAITracking<any>(reactPlugin, TelemetryProvider));